<template>
  <div>
    <!-- Header -->
    <div class="relative bg-gray-900 h-screen">
      <!-- Decorative image and overlay -->
      <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
        <img
          :src="home.Header_Image"
          alt=""
          class="w-full h-full object-center object-cover"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        />
        <div aria-hidden="true" class="absolute inset-0 bg-black opacity-60" />
      </div>

      <div
        class="relative max-w-3xl mx-auto py-40 px-6 flex flex-col items-center text-center sm:py-30 sm:py-40 lg:px-0"
      >
        <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <img class="h-10 mx-auto" src="@/assets/5.png" alt="" />
          <h1
            class="text-center mt-5 text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
          >
            <span class="text-white">One Squared. </span>
            <span class="text-sg">Bayside.</span>
          </h1>

          <h5
            class="rl mt-5 max-w-lg mx-auto text-center text-xl-2 text-white sm:max-w-3xl"
          >
            Breakfast, Lunch & Dinner.
          </h5>
          <div
            class="mt-5 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center"
          >
            <div
              class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5"
            >
              <router-link
                to="/menu"
                class="rl flex items-center justify-center px-4 py-3 border-2 border-sg text-base font-medium text-white hover:bg-pb hover:bg-opacity-50"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                View Menu
              </router-link>
              <router-link
                to="/contact"
                class="rl flex items-center justify-center px-4 py-3 border-2 border-sg text-base font-medium text-white hover:bg-pb hover:bg-opacity-50"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                Contact Us
              </router-link>
            </div>
          </div>
          <div class="flex flex-col pt-4 px-2 items-center">
            <div class="bg-white rounded-xl shadow-md">
              <Button />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Header -->
    <!-- About -->
    <div class="relative bg-sg shadow-md">
      <div class="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          class="w-full h-full object-cover"
          :src="home.Paragraph_Image"
          alt=""
        />
      </div>
      <div
        class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16"
      >
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <h3
            class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl"
          >
            {{ home.Paragraph_Title }}
          </h3>
          <p class="mt-3 text-lg text-white">
            {{ home.Paragraph_Body }}
          </p>
          <!-- <div
            class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5"
          >
            <router-link
              to="/contact"
              class="rl mt-5 flex items-center justify-center px-4 py-3 border-2 border-pb text-base font-medium text-white hover:bg-pb hover:bg-opacity-50"
            >
              View Full Menu
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
    <!-- About -->
    <!-- Section 1 -->
    <div class="bg-white">
      <div
        class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8"
      >
        <!-- Product details -->
        <div class="lg:max-w-lg lg:self-end">
          <nav aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-2">
              <li>
                <div class="flex items-center text-sm">
                  <span href="#" class="font-medium text-gray-500">
                    One Squared
                  </span>

                  <svg
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    aria-hidden="true"
                    class="ml-2 flex-shrink-0 h-5 w-5 text-sg"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                </div>
              </li>

              <li>
                <div class="flex items-center text-sm">
                  <router-link
                    to="/breakfast"
                    class="font-medium text-gray-500 hover:text-sg"
                  >
                    Breakfast
                  </router-link>
                </div>
              </li>
            </ol>
          </nav>

          <div class="mt-4">
            <h1
              class="text-3xl font-extrabold text-black sm:text-4xl sm:tracking-tight mb-5"
            >
              Breakfast.
            </h1>
          </div>

          <section aria-labelledby="information-heading" class="mt-4">
            <div class="mt-4 space-y-6">
              <p class="text-base text-gray-500">
                {{ home.Breakfast_Description }}
              </p>
            </div>
          </section>
        </div>

        <!-- Image -->
        <div class="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2 lg:self-center">
          <div class="aspect-w-1 aspect-h-1 shadow-md">
            <img
              :src="home.Breakfast_Image"
              alt="Model wearing light green backpack with black canvas straps and front zipper pouch."
              class="w-full h-full object-center object-cover"
            />
          </div>
        </div>
        <!-- Button -->
        <div
          class="mt-5 lg:max-w-lg lg:col-start-1 lg:row-start-2 lg:self-start"
        >
          <section aria-labelledby="options-heading">
            <div class="mt-10">
              <router-link
                to="/breakfast"
                class="rl w-full py-3 px-8 flex items-center justify-center border-2 border-sg text-base font-medium text-black hover:text-white hover:bg-pb hover:bg-opacity-50"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                Learn More
              </router-link>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- Section 1 -->
    <!-- Section 2 -->
    <div class="bg-white">
      <div
        class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8"
      >
        <!-- Product details -->
        <div class="lg:max-w-lg lg:self-end">
          <nav aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-2">
              <li>
                <div class="flex items-center text-sm">
                  <span href="#" class="font-medium text-gray-500">
                    One Squared
                  </span>

                  <svg
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    aria-hidden="true"
                    class="ml-2 flex-shrink-0 h-5 w-5 text-sg"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                </div>
              </li>

              <li>
                <div class="flex items-center text-sm">
                  <router-link
                    to="/lunch"
                    class="font-medium text-gray-500 hover:text-sg"
                  >
                    Lunch
                  </router-link>
                </div>
              </li>
            </ol>
          </nav>

          <div class="mt-4">
            <h1
              class="text-3xl font-extrabold text-black sm:text-4xl sm:tracking-tight mb-5"
            >
              Lunch.
            </h1>
          </div>

          <section aria-labelledby="information-heading" class="mt-4">
            <div class="mt-4 space-y-6">
              <p class="text-base text-gray-500">
                {{ home.Lunch_Description }}
              </p>
            </div>
          </section>
        </div>

        <!-- Image -->
        <div class="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2 lg:self-center">
          <div class="aspect-w-1 aspect-h-1 overflow-hidden shadow-md">
            <img
              :src="home.Lunch_Image"
              alt="Model wearing light green backpack with black canvas straps and front zipper pouch."
              class="w-full h-full object-center object-cover"
            />
          </div>
        </div>
        <!-- Button -->
        <div
          class="mt-5 lg:max-w-lg lg:col-start-1 lg:row-start-2 lg:self-start"
        >
          <section aria-labelledby="options-heading">
            <div class="mt-10">
              <router-link
                to="/lunch"
                class="rl w-full py-3 px-8 flex items-center justify-center border-2 border-sg text-base font-medium text-black hover:text-white hover:bg-pb hover:bg-opacity-50"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                Learn more
              </router-link>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- Section 2 -->
    <!-- Section 3 -->
    <div class="bg-white">
      <div
        class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8"
      >
        <!-- Product details -->
        <div class="lg:max-w-lg lg:self-end">
          <nav aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-2">
              <li>
                <div class="flex items-center text-sm">
                  <span href="#" class="font-medium text-gray-500">
                    One Squared
                  </span>

                  <svg
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    aria-hidden="true"
                    class="ml-2 flex-shrink-0 h-5 w-5 text-sg"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                </div>
              </li>

              <li>
                <div class="flex items-center text-sm">
                  <router-link
                    to="/dinner"
                    class="font-medium text-gray-500 hover:text-sg"
                  >
                    Dinner
                  </router-link>
                </div>
              </li>
            </ol>
          </nav>

          <div class="mt-4">
            <h1
              class="text-3xl font-extrabold text-black sm:text-4xl sm:tracking-tight mb-5"
            >
              Dinner.
            </h1>
          </div>

          <section aria-labelledby="information-heading" class="mt-4">
            <div class="mt-4 space-y-6">
              <p class="text-base text-gray-500">
                {{ home.Dinner_Description }}
              </p>
            </div>
          </section>
        </div>

        <!-- Image -->
        <div class="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2 lg:self-center">
          <div class="aspect-w-1 aspect-h-1 overflow-hidden shadow-md">
            <img
              :src="home.Dinner_Image"
              alt="Model wearing light green backpack with black canvas straps and front zipper pouch."
              class="w-full h-full object-center object-cover"
            />
          </div>
        </div>
        <!-- Button -->
        <div
          class="mt-5 lg:max-w-lg lg:col-start-1 lg:row-start-2 lg:self-start"
        >
          <section aria-labelledby="options-heading">
            <div class="mt-10">
              <router-link
                to="/dinner"
                class="rl w-full py-3 px-8 flex items-center justify-center border-2 border-sg text-base font-medium text-black hover:text-white hover:bg-pb hover:bg-opacity-50"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                Learn more
              </router-link>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- Section 3 -->
  </div>
</template>

//
<script setup>
// import { defineComponent, computed, reactive } from "vue";
// import { useHead } from "@vueuse/head";

// defineComponent({
//   setup() {
//     const siteData = reactive({
//       title: `My website`,
//       description: `My beautiful website`,
//     });

//     useHead({
//       // Can be static or computed
//       title: computed(() => siteData.title),
//       meta: [
//         {
//           name: `description`,
//           content: computed(() => siteData.description),
//         },
//       ],
//     });
//   },
// });
//
</script>

<script>
import axios from "axios";
import Button from "../components/opentable/Button.vue";

export default {
  data() {
    return {
      home: {
        Header_Image: "",
        Paragraph_Image: "",
        Paragraph_Title: "",
        Paragraph_Body: "",
        Paragraph_Body: "",
        Breakfast_Description: "",
        Breakfast_Image: "",
        Lunch_Description: "",
        Lunch_Image: "",
        Dinner_Description: "",
        Dinner_Image: "",
      },
      components: {
        Button,
      },
    };
  },

  methods: {
    getHomePage() {
      let __this = this;
      axios
        .get(
          "https://api.storyblok.com/v2/cdn/stories/home?cv=1657612108&token=e90vmTfGJpbIf0qqX2mihwtt&version=published"
        )
        .then((response) => {
          let homeContent = response.data["story"]["content"];
          __this.home = {
            Header_Image: homeContent["Header_Image"]["filename"],
            Paragraph_Image: homeContent["Paragraph_Image"]["filename"],
            Paragraph_Title: homeContent["Paragraph_Title"],
            Paragraph_Body: homeContent["Paragraph_Body"],
            Breakfast_Description: homeContent["Breakfast_Description"],
            Breakfast_Image: homeContent["Breakfast_Image"]["filename"],
            Lunch_Description: homeContent["Lunch_Description"],
            Lunch_Image: homeContent["Lunch_Image"]["filename"],
            Dinner_Description: homeContent["Dinner_Description"],
            Dinner_Image: homeContent["Dinner_Image"]["filename"],
          };
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.getHomePage();
  },
};
</script>

<style></style>
